/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react/jsx-key */
/* eslint-disable @typescript-eslint/no-unsafe-call */
import classNames from "classnames";
import React, { PropsWithChildren } from "react";
import { GraphCms_Position } from "../generated/graphql-types";
import { ProfileInformation } from "./SingleProfile";
import linkIcon from "../images/Link Button White.svg";

function Redirect(url: string | undefined) {
  window.location.href = url;
}

export const Researchblock: React.FC<
  PropsWithChildren<{
    profile?: ProfileInformation;
    Title?: string;
    Link?: string;
    quote?: string;
    author?: string;
    authorJobTitle?: string;
    authorPosition?: GraphCms_Position;
    className?: string;
    CTA?: string;
    btn?: string;
    contentclass?: string;
    infograph?: object;
    infographTitle?: string;
    infographtitlclassName?: string;
    sessionBackground?: string;
    infographicImage?: string;
    ctaText?: string;
  }>
> = ({
  quote,
  className,
  Title,
  Link,
  CTA,
  btn,
  contentclass,
  infograph,
  infographTitle,
  infographicImage,
  infographtitlclassName,
  ctaText,
}) => {
  // if (!quote) {
  //   return null;
  // }
  if (CTA == "CTA") {
    return (
      <section
        data-component="Researchblock"
        className={classNames(
          "py-section relative  max-width z-10 bg-light-blue",
          className
        )}
      >
        <h5 className="row-start-1 col-start-2 col-span-12 text-center h7 text-white-1 ga-CTA-heading ga-font-style-s24">
          {Title}
        </h5>
        <div className="text-center self-center col-start-2 col-span-12 lg:col-start-4 lg:col-span-8 text-white-1 text-center">
          {quote}
          <br />
          <center>
            <button
              href={Link}
              onClick={() => Redirect(Link)}
              className="col-start-4 col-span-8 pt-20px sm:pt-none sm:col-start-8 sm:col-span-5 md:col-start-11 md:col-span-3 md:row-start-1 flex self-center ga-button-CTA"
            >
              <h4 className="self-center">{ctaText ? ctaText : "Read more"}</h4>
            </button>
          </center>
        </div>
      </section>
    );
  } else if (CTA == "Insights") {
    return (
      <section
        data-component="Researchblock"
        className={classNames(
          "py-section relative page-grid max-width z-10 bg-dark-blue",
          className
        )}
      >
        <h2 className="row-start-1 col-start-3 col-span-10 text-center h7 text-white-1">
          {Title}
        </h2>
        <div className="text-center self-center col-start-2 col-span-12 lg:col-start-4 lg:col-span-8 text-white-1 text-center">
          {quote}
          <br />
          <br />
          <a
            href={Link}
            className="col-start-4 col-span-8 pt-20px sm:pt-none sm:col-start-8 sm:col-span-5 md:col-start-11 md:col-span-3 md:row-start-1 flex justify-center"
          >
            <h4 className="lg:h3 p font-bold self-center">
              {ctaText ? ctaText : "Read more"}
              <img
                src={linkIcon}
                alt="Right"
                className="float-right pl-mobile-gap md:pl-tablet-gap lg:pl-desktop-gap"
              />
            </h4>
          </a>
        </div>
      </section>
    );
  } else if (CTA == "Insightsblock") {
    return (
      <section
        data-component="Researchblock"
        className={classNames(
          "py-section relative page-grid max-width z-10 bg-dark-blue",
          className
        )}
      >
        {/* <center> */}
        <div className="text-center self-center col-start-2 col-span-12 lg:col-start-4 lg:col-span-8 text-white-1 text-center">
          <h5
            className={classNames(
              "ga-ptb-30 row-start-1 col-start-2 col-span-12 text-center h7 text-white-1 ga-block-heading ga-font-style-s24 ga-line-height-42 ",
              className
            )}
          >
            <center>{Title}</center>
          </h5>
          {quote}
          <br />
          <br />
          <a
            href={Link}
            className="col-start-4 col-span-8 pt-20px sm:pt-none sm:col-start-8 sm:col-span-5 md:col-start-11 md:col-span-3 md:row-start-1 flex justify-center"
          >
            <center>
              <button
                href={Link}
                type="button"
                onClick={() => Redirect(Link)}
                className="col-start-4 col-span-8 pt-20px sm:pt-none sm:col-start-8 sm:col-span-5 md:col-start-11 md:col-span-3 md:row-start-1 flex self-center ga-button-effect ga-mt-40"
              >
                <h4 className="self-center">
                  {ctaText ? ctaText : "Read more"}
                </h4>
              </button>
            </center>
          </a>
        </div>
        {/* </center> */}
      </section>
    );
  } else if (CTA == "OUR-PEOPLE") {
    return (
      <section
        data-component="Researchblock"
        className={classNames(
          "py-section page-grid max-width z-0 bg-dark-blue ga-text-color",
          className
        )}
      >
        <h5
          className={classNames(
            "ga-ptb-30 row-start-1 col-start-2 col-span-12 text-center h7 text-white-1 ga-block-heading ga-font-style-s24 ga-line-height-42",
            className
          )}
        >
          <center>{Title}</center>
        </h5>
        <div
          className={classNames(
            "text-center self-center col-start-2 col-span-12 lg:col-start-2 lg:col-span-12 text-white-1 text-right-margin ga-font-style-s16 ga-line-height-24 ",
            contentclass
          )}
        >
          <center className="ga-our-people-block">{quote}</center>
        </div>
        {infographTitle && (
          <div className="col-span-12 col-start-2">
            <p
              className={classNames(
                "row-start-1 col-start-3 col-span-10  h7 text-white-1 font-normal ga-font-style-s16 text-center ga-mobile-infocontent",
                infographtitlclassName
              )}
            >
              <b>{infographTitle}</b>
            </p>
          </div>
        )}
        {infograph && (
          <div className="col-span-12 col-start-2 ga-infographic-div text-dark-blue">
            {/* <div className="grid grid-cols-1 md:grid-cols-3 w-full mt-50px"> */}
            <div className="grid grid-cols-1 md:grid-cols-1 w-full ">
              {/* {infograph.map(
                (n: {
                  value:
                    | string
                    | number
                    | boolean
                    | React.ReactElement<
                        any,
                        string | React.JSXElementConstructor<any>
                      >
                    | React.ReactFragment
                    | React.ReactPortal
                    | null
                    | undefined;
                }) => (
                  <div className="col-span-1 ga-infographic1-border">
                    <div className="col-span-12 ga-padding-10 ga-background-lightgrey h-full">
                      {n.value}
                    </div>
                  </div>
                )
              )} */}
              <img
                src={infographicImage}
                className="ga-image-quotes-person-image w-full"
              />
            </div>
          </div>
        )}
      </section>
    );
  } else {
    return (
      <section
        data-component="Researchblock"
        className={classNames(
          "py-section page-grid max-width z-0 bg-dark-blue ga-text-color",
          className
        )}
      >
        <h5
          className={classNames(
            "ga-ptb-30 row-start-1 col-start-2 col-span-12 text-center h7 text-white-1 ga-block-heading ga-font-style-s24 ga-line-height-42",
            className
          )}
        >
          {Title}
        </h5>
        <div
          className={classNames(
            "text-center self-center col-start-2 col-span-12 lg:col-start-2 lg:col-span-12 text-white-1 text-right-margin ga-font-style-s16 ga-line-height-24 ",
            contentclass
          )}
        >
          {quote}
          {!btn && (
            <center>
              <button
                href={Link}
                type="button"
                onClick={() => Redirect(Link)}
                className="col-start-4 col-span-8 pt-20px sm:pt-none sm:col-start-8 sm:col-span-5 md:col-start-11 md:col-span-3 md:row-start-1 flex self-center ga-button-effect ga-mt-40"
              >
                <h4 className="self-center">Read more</h4>
              </button>
            </center>
          )}
        </div>
        {infographTitle && (
          <div className="col-span-12 col-start-2">
            <p
              className={classNames(
                "row-start-1 col-start-3 col-span-10  h7 text-white-1 font-normal ga-font-style-s16 text-center ga-mobile-infocontent",
                infographtitlclassName
              )}
            >
              <b>{infographTitle}</b>
            </p>
          </div>
        )}
        {infograph && (
          <div className="col-span-12 col-start-2 ga-infographic-div text-dark-blue">
            {/* <div className="grid grid-cols-1 md:grid-cols-3 w-full mt-50px"> */}
            <div className="grid grid-cols-1 md:grid-cols-1 w-full ">
              {/* {infograph.map(
                (n: {
                  value:
                    | string
                    | number
                    | boolean
                    | React.ReactElement<
                        any,
                        string | React.JSXElementConstructor<any>
                      >
                    | React.ReactFragment
                    | React.ReactPortal
                    | null
                    | undefined;
                }) => (
                  <div className="col-span-1 ga-infographic1-border">
                    <div className="col-span-12 ga-padding-10 ga-background-lightgrey h-full">
                      {n.value}
                    </div>
                  </div>
                )
              )} */}
              <img
                src={infographicImage}
                className="ga-image-quotes-person-image w-full"
              />
            </div>
          </div>
        )}
      </section>
    );
  }
};
